// src/pages/BlogDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const BlogDetail = () => {
  const { id } = useParams(); // Extract the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
    // Fetch the specific blog by ID
    fetch(`https://dawamaxma.dawamtaani.co.ke/api/blogs/${id}`)
      .then((response) => response.json())
      .then((data) => setBlog(data))
      .catch((error) => console.error('Error fetching blog:', error));
  }, [id]);

  if (!blog) return <div className="min-h-screen flex items-center justify-center">Loading...</div>;

  return (
    <div className="min-h-screen p-8 bg-gray-50">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-5xl font-extrabold text-center text-gray-800 mb-4">{blog.title}</h1>
          <p className="text-lg text-center text-gray-500 mb-6">{blog.subheading}</p>
          <img
            src={`https://dawamaxma.dawamtaani.co.ke/${blog.image}`}
            alt={blog.title}
            className="w-full h-64 object-cover rounded-lg shadow-md mb-6"
          />
          <div className="prose prose-lg max-w-none text-gray-700">
            {blog.content.split('\n').map((paragraph, index) => (
              <p key={index} className="mb-4 leading-relaxed">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        <div className="px-6 py-4 bg-gray-100 border-t text-center">
          <button
            onClick={() => navigate('/blogs')}
            className="text-pink-700 font-semibold hover:underline"
          >
            Back to Blogs
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
