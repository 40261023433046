// src/pages/AllBlogs.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const imageBaseUrl = 'https://dawamaxma.dawamtaani.co.ke';

  useEffect(() => {
    fetch('https://dawamaxma.dawamtaani.co.ke/api/blogs')
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error('Error fetching blogs:', error));
  }, []);

  return (
    <div className="min-h-screen p-8 ">
      <h1 className="text-6xl font-extrabold text-center text-black mb-12">FEATURED BLOGS</h1>
      <div className="grid gap-8 max-w-6xl mx-auto md:grid-cols-2 lg:grid-cols-3">
        {blogs.map((blog) => (
          <div key={blog.id} className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            <img
              src={`${imageBaseUrl}/${blog.image}`}
              alt={blog.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h2 className="text-xl font-bold text-white mb-2">{blog.title}</h2>
              <p className="text-white mb-4">{blog.subheading}</p>
              <Link
                to={`/blogs/${blog.id}`}
                className="text-pink-500 font-semibold hover:underline"
              >
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllBlogs;
