import React, { useState, useEffect } from 'react';
import { X } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PathFinderLoader } from '../components/PathFinderLoader';

const AdminPanel = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1); // 1 for Products, 2 for Blogs
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isBlogEditModalOpen, setIsBlogEditModalOpen] = useState(false);
  const [isBlogAddModalOpen, setIsBlogAddModalOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [productFormData, setProductFormData] = useState({
    itemName: '',
    category: '',
    retailPrice: '',
    description: '',
    precautions: '',
    image: null,
  });
  const [blogFormData, setBlogFormData] = useState({
    title: '',
    subheading: '',
    content: '',
    image: null,
  });

  useEffect(() => {
    const fetchProductsAndCategories = async () => {
      try {
        const [productsResponse, categoriesResponse, blogsResponse] = await Promise.all([
          fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy'),
          fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy/categories'),
          fetch('https://dawamaxma.dawamtaani.co.ke/api/blogs'), // Adjust endpoint as necessary
        ]);

        if (!productsResponse.ok) throw new Error('Failed to fetch products');
        if (!categoriesResponse.ok) throw new Error('Failed to fetch categories');
        if (!blogsResponse.ok) throw new Error('Failed to fetch blogs');

        const [productsData, categoriesData, blogsData] = await Promise.all([
          productsResponse.json(),
          categoriesResponse.json(),
          blogsResponse.json(),
        ]);

        setProducts(productsData);
        setFilteredProducts(productsData);
        setCategories(categoriesData);
        setBlogs(blogsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsAndCategories();
  }, []);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const matchesCategory = selectedCategory ? product.category === selectedCategory : true;
      const matchesSearch = searchQuery
        ? product.itemName?.toLowerCase().includes(searchQuery.toLowerCase())
        : true;
      return matchesCategory && matchesSearch;
    });
    setFilteredProducts(filtered);
  }, [searchQuery, selectedCategory, products]);

  const handleOpenEditModal = (product) => {
    setSelectedProduct(product);
    setProductFormData({
      itemName: product.itemName,
      category: product.category,
      retailPrice: product.retailPrice,
      description: product.description || '',
      precautions: product.precautions || '',
      image: product.image,
    });
    setIsEditModalOpen(true);
  };

  const handleOpenAddModal = () => {
    setProductFormData({
      itemName: '',
      category: '',
      retailPrice: '',
      description: '',
      precautions: '',
      image: null,
    });
    setIsAddModalOpen(true);
  };

  const handleOpenBlogEditModal = (blog) => {
    setSelectedBlog(blog);
    setBlogFormData({
      title: blog.title,
      subheading: blog.subheading,
      content: blog.content,
      image: blog.image,
    });
    setIsBlogEditModalOpen(true);
  };

  const handleOpenBlogAddModal = () => {
    setBlogFormData({
      title: '',
      subheading: '',
      content: '',
      image: null,
    });
    setIsBlogAddModalOpen(true);
  };

  const handleCloseModals = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setIsBlogEditModalOpen(false);
    setIsBlogAddModalOpen(false);
    setSelectedProduct(null);
    setSelectedBlog(null);
  };

  const handleInputChange = (e, isBlog = false) => {
    const { name, value } = e.target;
    if (isBlog) {
      setBlogFormData({ ...blogFormData, [name]: value });
    } else {
      setProductFormData({ ...productFormData, [name]: value });
    }
  };

  const handleFileChange = (e, isBlog = false) => {
    const file = e.target.files[0];
    if (isBlog) {
      setBlogFormData({ ...blogFormData, image: file });
    } else {
      setProductFormData({ ...productFormData, image: file });
    }
  };

  const handleSaveProduct = async () => {
    try {
      const formDataObj = new FormData();
      Object.keys(productFormData).forEach((key) => {
        formDataObj.append(key, productFormData[key]);
      });

      const url = selectedProduct
        ? `https://dawamaxma.dawamtaani.co.ke/api/pharmacy/${selectedProduct.id}`
        : 'https://dawamaxma.dawamtaani.co.ke/api/pharmacy';

      const method = selectedProduct ? 'PATCH' : 'POST';

      const response = await fetch(url, {
        method,
        body: formDataObj,
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      const savedProduct = await response.json();
      if (selectedProduct) {
        setProducts(products.map((product) => (product.id === savedProduct.id ? savedProduct : product)));
        setFilteredProducts(filteredProducts.map((product) => (product.id === savedProduct.id ? savedProduct : product)));
        toast.success('Product updated successfully');
      } else {
        setProducts([...products, savedProduct]);
        setFilteredProducts([...filteredProducts, savedProduct]);
        toast.success('Product added successfully');
      }

      handleCloseModals();
    } catch (error) {
      toast.error('Failed to save the product');
    }
  };

  const handleSaveBlog = async () => {
    try {
      const formDataObj = new FormData();
      Object.keys(blogFormData).forEach((key) => {
        formDataObj.append(key, blogFormData[key]);
      });

      const url = selectedBlog
        ? `https://dawamaxma.dawamtaani.co.ke/api/blogs/${selectedBlog.id}`
        : 'https://dawamaxma.dawamtaani.co.ke/api/blogs';

      const method = selectedBlog ? 'PATCH' : 'POST';

      const response = await fetch(url, {
        method,
        body: formDataObj,
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      const savedBlog = await response.json();
      if (selectedBlog) {
        setBlogs(blogs.map((blog) => (blog.id === savedBlog.id ? savedBlog : blog)));
        toast.success('Blog updated successfully');
      } else {
        setBlogs([...blogs, savedBlog]);
        toast.success('Blog added successfully');
      }

      handleCloseModals();
    } catch (error) {
      toast.error('Failed to save the blog');
    }
  };

  if (loading) {
    return <PathFinderLoader />; 
  }

  if (error) {
    return <div className="text-center text-red-600">{`Error: ${error}`}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 md:py-16 font-sans">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center">Admin Panel - Product & Blog Management</h1>

      <TabNavigation selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === 1 && (
        <ProductSection
          products={filteredProducts}
          categories={categories}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenAddModal={handleOpenAddModal}
        />
      )}

      {selectedTab === 2 && (
        <BlogSection
          blogs={blogs}
          handleOpenBlogEditModal={handleOpenBlogEditModal}
          handleOpenBlogAddModal={handleOpenBlogAddModal}
        />
      )}

      {/* Product Modal */}
      {(isEditModalOpen || isAddModalOpen) && (
        <Modal
          isOpen={isEditModalOpen || isAddModalOpen}
          onClose={handleCloseModals}
          title={isEditModalOpen ? 'Edit Product' : 'Add Product'}
          formData={productFormData}
          onInputChange={handleInputChange}
          onFileChange={handleFileChange}
          onSave={handleSaveProduct}
          isBlog={false}
          categories={categories}
        />
      )}

      {/* Blog Modal */}
      {(isBlogEditModalOpen || isBlogAddModalOpen) && (
        <Modal
          isOpen={isBlogEditModalOpen || isBlogAddModalOpen}
          onClose={handleCloseModals}
          title={isBlogEditModalOpen ? 'Edit Blog' : 'Add Blog'}
          formData={blogFormData}
          onInputChange={(e) => handleInputChange(e, true)}
          onFileChange={(e) => handleFileChange(e, true)}
          onSave={handleSaveBlog}
          isBlog={true}
        />
      )}

      <ToastContainer />
    </div>
  );
};

const TabNavigation = ({ selectedTab, setSelectedTab }) => {
  const tabs = [
    { id: 1, title: 'Products' },
    { id: 2, title: 'Blogs' },
  ];

  return (
    <div className="flex flex-wrap justify-center mb-4 md:mb-8">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
          className={`px-3 py-2 m-1 md:mx-2 font-semibold rounded ${
            selectedTab === tab.id ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-600'
          }`}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};

const ProductSection = ({
  products,
  categories,
  searchQuery,
  setSearchQuery,
  selectedCategory,
  setSelectedCategory,
  handleOpenEditModal,
  handleOpenAddModal,
}) => (
  <div>
    <div className="flex flex-col md:flex-row justify-between mb-4 md:mb-6">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-2 md:mb-0">
        <input
          type="text"
          placeholder="Product Name..."
          className="border p-2 rounded w-full md:w-auto"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="border p-2 rounded w-full md:w-auto"
          value={selectedCategory}
        >
          <option value="">All Categories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <button
        className="bg-orange-500 text-white py-2 px-4 rounded"
        onClick={handleOpenAddModal}
      >
        Add Product
      </button>
    </div>

    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg">
        <thead>
          <tr className="text-sm md:text-base">
            <th className="px-2 py-1 md:px-4 md:py-2 border">ID</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Image</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Product Name</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Category</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Price</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Created Date</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id} className="text-sm md:text-base">
              <td className="px-2 py-1 md:px-4 md:py-2 border">{product.id}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">
                <img
                  src={product.image ? `https://dawamaxma.dawamtaani.co.ke${product.image}` : '/default-image-path'}
                  alt={product.itemName}
                  className="w-12 h-12 md:w-16 md:h-16 rounded-lg object-cover"
                />
              </td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{product.itemName}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{product.category}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">Ksh {parseFloat(product.retailPrice).toFixed(2)}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{product.createdAt}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded"
                  onClick={() => handleOpenEditModal(product)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const BlogSection = ({ blogs, handleOpenBlogEditModal, handleOpenBlogAddModal }) => (
  <div>
    <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Blogs</h2>
    <button
      className="bg-green-500 text-white py-2 px-4 rounded mb-4"
      onClick={handleOpenBlogAddModal}
    >
      Add Blog
    </button>
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border rounded-lg">
        <thead>
          <tr className="text-sm md:text-base">
            <th className="px-2 py-1 md:px-4 md:py-2 border">ID</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Image</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Title</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Subheading</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Created Date</th>
            <th className="px-2 py-1 md:px-4 md:py-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {blogs.map((blog) => (
            <tr key={blog.id} className="text-sm md:text-base">
              <td className="px-2 py-1 md:px-4 md:py-2 border">{blog.id}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">
                <img
                  src={blog.image ? `https://dawamaxma.dawamtaani.co.ke${blog.image}` : '/default-image-path'}
                  alt={blog.title}
                  className="w-12 h-12 md:w-16 md:h-16 rounded-lg object-cover"
                />
              </td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{blog.title}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{blog.subheading}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">{blog.created_at}</td>
              <td className="px-2 py-1 md:px-4 md:py-2 border">
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded"
                  onClick={() => handleOpenBlogEditModal(blog)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, title, formData, onInputChange, onFileChange, onSave, isBlog, categories }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-4">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X className="w-6 h-6" />
        </button>
        <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>
        <div className="flex flex-col space-y-4">
          {!isBlog && (
            <>
              <input
                type="text"
                name="itemName"
                value={formData.itemName}
                onChange={onInputChange}
                placeholder="Product Name"
                className="border p-2 rounded"
              />
              <select
                name="category"
                value={formData.category}
                onChange={onInputChange}
                className="border p-2 rounded"
              >
                <option value="">Select Category</option>
                {categories && categories.map((cat) => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
              <input
                type="number"
                name="retailPrice"
                value={formData.retailPrice}
                onChange={onInputChange}
                placeholder="Price"
                className="border p-2 rounded"
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={onInputChange}
                placeholder="Description"
                className="border p-2 rounded"
                rows="3"
              />
              <textarea
                name="precautions"
                value={formData.precautions}
                onChange={onInputChange}
                placeholder="Precautions"
                className="border p-2 rounded"
                rows="3"
              />
            </>
          )}
          {isBlog && (
            <>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={onInputChange}
                placeholder="Title"
                className="border p-2 rounded"
              />
              <input
                type="text"
                name="subheading"
                value={formData.subheading}
                onChange={onInputChange}
                placeholder="Subheading"
                className="border p-2 rounded"
              />
              <textarea
                name="content"
                value={formData.content}
                onChange={onInputChange}
                placeholder="Content"
                className="border p-2 rounded"
                rows="3"
              />
            </>
          )}
          <input
            type="file"
            onChange={onFileChange}
            className="border p-2 rounded"
          />
          {formData.image && (
            <img
              src={
                typeof formData.image === 'string'
                  ? (formData.image.startsWith('/uploads') || formData.image.startsWith('http'))
                    ? `https://dawamaxma.dawamtaani.co.ke${formData.image}`
                    : formData.image
                  : URL.createObjectURL(formData.image)
              }
              alt="Preview"
              className="w-24 h-24 rounded mt-2 object-cover"
            />
          )}
        </div>
        <button
          onClick={onSave}
          className="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AdminPanel;
