import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useCart } from './Cart';
import { Link, useNavigate } from 'react-router-dom';
import { cardio } from 'ldrs';

cardio.register();

const FeaturedProducts = () => {
  const [products, setProducts] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCategories, setActiveCategories] = useState(['', '', '']);
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const defaultImage = "https://images.unsplash.com/photo-1471864190281-a93a3070b6de?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3";

  useEffect(() => {
    const fetchProductsAndCategories = async () => {
      try {
        const productsResponse = await fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy');
        if (!productsResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const productsData = await productsResponse.json();

        const groupedProducts = productsData.reduce((acc, product) => {
          const { category } = product;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(product);
          return acc;
        }, {});

        const categoriesResponse = await fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy/categories');
        if (!categoriesResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const categoriesData = await categoriesResponse.json();

        setCategories(categoriesData);
        setProducts(groupedProducts);

        if (categoriesData.length > 0) {
          setActiveCategories(categoriesData.slice(0, 3));
        }

      } catch (error) {
        console.error('Error fetching products or categories:', error);
        setError('Failed to fetch products and categories. Please try again later.');
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    };

    fetchProductsAndCategories();
  }, []);

  const handleCategoryChange = (index, newCategory) => {
    setActiveCategories((prev) => {
      const updatedCategories = [...prev];
      updatedCategories[index] = newCategory;
      return updatedCategories;
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-16">
        <l-cardio size="50" stroke="4" speed="2" color="black"></l-cardio>
        <p className="text-xl ml-4">Loading featured products...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center py-16">
        <p className="text-xl text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-center mb-6 text-black">Featured Products</h2>
        {activeCategories.map((activeCategory, index) => {
          const displayedProducts = products[activeCategory]?.slice(0, 3) || [];
          return (
            <div key={index} className="mb-8">
              <div className="mb-4">
                <select
                  value={activeCategory}
                  onChange={(e) => handleCategoryChange(index, e.target.value)}
                  className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {displayedProducts.map((product) => (
                  <motion.div
                    key={product.id}
                    className="bg-white shadow-md rounded-lg p-6"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={product.image ? `https://dawamaxma.dawamtaani.co.ke${product.image}` : defaultImage}
                      alt={product.itemName}
                      className="w-full h-64 object-cover rounded-lg mb-4"
                    />
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">{product.itemName}</h3>
                    <p className="text-gray-600 mb-4">Ksh {product.retailPrice}</p>
                    <div className="flex justify-between">
                      <button
                        onClick={() => addToCart(product)}
                        className="w-1/2 bg-gray-800 text-white py-2 rounded-full hover:bg-gray-900 mr-2"
                      >
                        Add to Cart
                      </button>
                      <button
                        onClick={() => navigate(`/product-details/${product.id}`)}
                        className="w-1/2 bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600"
                      >
                        View Product
                      </button>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          );
        })}
        <div className="flex justify-center mt-8">
          <Link to="/shop" className="text-blue-500 font-semibold hover:underline">
            View All Products
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProducts;
