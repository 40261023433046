import React from 'react';
import { X } from 'react-feather';

const Modal = ({ closeModal }) => {
  const handleWhatsAppShare = () => {
    // Construct the message for WhatsApp
    const message = `Please find attached below my prescription. Could you tell me about the availability and the price of individual items?`;
    const whatsappUrl = `https://wa.me/254101757541?text=${encodeURIComponent(message)}`;

    // Open WhatsApp with the constructed message
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4 md:p-0">
      <div className="bg-white w-full max-w-md p-6 rounded-lg relative text-center">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-red-500 hover:text-red-700"
        >
          <X size={18} />
        </button>

        <img
          src="https://images.unsplash.com/photo-1512069772995-ec65ed45afd6?q=80&w=1037&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Update the path to your heart image
          alt=""
          className="w-full rounded-lg object-cover max-h-48 mx-auto"
        />

        
        <h2 className="text-2xl font-bold text-gray-800 mt-4 font-poppins">
          Prescriptions Delivered To Your Door
        </h2>
        <p className="text-gray-600 mt-2">
          To ensure the security of your data, we are currently taking prescriptions via WhatsApp only. Please click below to submit your prescription. You will be redirected to WhatsApp and speak directly to a Pharmacist from our team.
        </p>
        
        <p className="text-gray-600 mt-2">
          Please find attached below my prescription.
        </p>

        <button
          className="mt-6 w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 flex items-center justify-center"
          onClick={handleWhatsAppShare}
        >
          Submit Prescription
        </button>
      </div>
    </div>
  );
};

export default Modal;
