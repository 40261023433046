import React, { useState, useEffect, useRef, useCallback } from "react";
import { ShoppingCart, X, Trash, PlusCircle, MinusCircle, Menu } from "react-feather";
import { useCart } from "./Cart";
import { NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { cartItems, removeFromCart, clearCart, updateCartItemQuantity } = useCart();
  const cartRef = useRef(null);
  const menuRef = useRef(null);
  const searchRef = useRef(null);
  const [iconBounce, setIconBounce] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure clicks inside the dropdown or on scrollbars don't close it
      if (cartRef.current && !cartRef.current.contains(event.target) && !searchRef.current.contains(event.target)) {
        setCartOpen(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      // Close search results only if the click is outside both the search input and dropdown
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setFilteredProducts([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartRef, menuRef, searchRef]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setIconBounce(true);
      const timeout = setTimeout(() => setIconBounce(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [cartItems]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = products.filter(product => 
        product.itemName?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleSelectProduct = useCallback((productId) => {
    setSearchTerm('');
    setFilteredProducts([]);
    setTimeout(() => {
      navigate(`/product-details/${productId}`);
    }, 100);
  }, [navigate]);

  const handleCheckout = () => {
    if (cartItems.length === 0) return;
    const message = cartItems
      .map(
        (item) => `${item.quantity} x ${item.itemName} at ${item.retailPrice}`
      )
      .join(", ");
    const whatsappUrl = `https://wa.me/254101757541?text=I am interested in: ${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const toggleCart = () => setCartOpen(!cartOpen);

  const handleViewCart = () => {
    toggleCart();
    navigate("/view-cart");
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="sticky top-0 bg-white shadow-md w-full z-10">
      {/* Top Bar */}
      <div className="bg-green-600 text-white text-center py-1 text-sm">
        Free Delivery For Orders Above 2,500/=
      </div>

      {/* Main Navbar */}
      <div className="container mx-auto flex items-center justify-between p-4 relative">
        {/* Logo */}
        <NavLink to="/" className="flex items-center">
          <img
            src={process.env.PUBLIC_URL + "/logo512.png"}
            alt="DawaMtaani Logo"
            className="h-28 w-auto cursor-pointer"
          />
        </NavLink>

        {/* Hamburger Menu (Mobile) */}
        <div className="md:hidden flex items-center space-x-4">
          <Menu
            className="text-xl text-gray-800 cursor-pointer"
            onClick={toggleMenu}
          />
          <div className="relative" onClick={toggleCart}>
            <motion.div
              animate={iconBounce ? { scale: [1, 1.5, 1] } : {}}
              transition={{ duration: 0.5 }}
              className="inline-block"
            >
              <ShoppingCart className="text-xl text-gray-800 cursor-pointer" />
            </motion.div>
            {cartItems.length > 0 && (
              <span className="absolute top-[-5px] right-[-8px] bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                {cartItems.length}
              </span>
            )}
          </div>
        </div>

        {/* Search Bar (Desktop) */}
        <div className="hidden md:flex items-center justify-center w-full absolute left-1/2 transform -translate-x-1/2">
          <div className="flex items-center bg-gray-100 rounded-full px-4 py-2 w-full max-w-lg relative" ref={searchRef}>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search for products"
              className="bg-transparent outline-none text-gray-600 flex-grow"
            />
            <button className="text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.9 14.32a8 8 0 111.414-1.414l4.387 4.387a1 1 0 01-1.414 1.414l-4.387-4.387zM8 14A6 6 0 108 2a6 6 0 000 12z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {/* Search Results Dropdown */}
            {filteredProducts.length > 0 && (
              <div
                className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-b-lg z-10 max-h-60 overflow-y-auto"
                onMouseDown={(e) => e.stopPropagation()} // Prevent dropdown from closing on clicks within
              >
                {filteredProducts.map((product) => (
                  <div
                    key={product.id}
                    onClick={() => handleSelectProduct(product.id)}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center justify-between"
                  >
                    <span className="text-gray-700">{product.itemName}</span>
                    <span className="text-sm text-gray-500">Ksh {product.retailPrice}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Links and Cart (Desktop) */}
        <div className="hidden md:flex items-center space-x-4">
          
          <div className="relative" onClick={toggleCart}>
            <motion.div
              animate={iconBounce ? { scale: [1, 1.5, 1] } : {}}
              transition={{ duration: 0.5 }}
              className="inline-block"
            >
              <ShoppingCart className="text-xl text-gray-800 cursor-pointer" />
            </motion.div>
            {cartItems.length > 0 && (
              <span className="absolute top-[-5px] right-[-8px] bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                {cartItems.length}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Search Bar (Mobile) */}
      <div className="md:hidden flex items-center justify-center p-4">
        <div className="flex items-center bg-gray-100 rounded-full px-4 py-2 w-full max-w-md relative" ref={searchRef}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search for products"
            className="bg-transparent outline-none text-gray-600 flex-grow"
          />
          <button className="text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.9 14.32a8 8 0 111.414-1.414l4.387 4.387a1 1 0 01-1.414 1.414l-4.387-4.387zM8 14A6 6 0 108 2a6 6 0 000 12z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {/* Search Results Dropdown (Mobile) */}
          {filteredProducts.length > 0 && (
            <div
              className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-b-lg z-10 max-h-60 overflow-y-auto"
              onMouseDown={(e) => e.stopPropagation()} // Prevent dropdown from closing on clicks within
            >
              {filteredProducts.map((product) => (
                <div
                  key={product.id}
                  onClick={() => handleSelectProduct(product.id)}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center justify-between"
                >
                  <span className="text-gray-700">{product.itemName}</span>
                  <span className="text-sm text-gray-500">Ksh {product.retailPrice}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Bottom Links (Desktop Only) */}
      <div className="hidden md:flex bg-gray-100 py-2">
        <div className="container mx-auto flex justify-center space-x-6 text-sm text-gray-700">
          <NavLink to="/shop" className="hover:text-gray-800">
            Shop By Category
          </NavLink>
          <NavLink to="/shop-by-condition" className="hover:text-gray-800">
            Shop By Condition
          </NavLink>
          <NavLink to="/shop-by-brand" className="hover:text-gray-800">
            Shop By Brand
          </NavLink>
          <NavLink to="/sale-offers" className="hover:text-gray-800">
            Sale & Offers
          </NavLink>
          <NavLink to="/submit-prescription" className="hover:text-gray-800">
            Submit Prescription
          </NavLink>
          <NavLink to="/health-services" className="hover:text-gray-800">
            Health Services
          </NavLink>
          <NavLink to="/store-locator" className="hover:text-gray-800">
            Store Locator
          </NavLink>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef}
            className="fixed inset-0 bg-white text-gray-600 p-4 z-20 overflow-y-auto"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            {/* Close Button */}
            <div className="flex justify-end">
              <X
                className="text-gray-800 cursor-pointer"
                onClick={toggleMenu}
              />
            </div>
            <NavLink
              to="/shop"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Shop By Category
            </NavLink>
            <NavLink
              to="/shop-by-condition"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Shop By Condition
            </NavLink>
            <NavLink
              to="/shop-by-brand"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Shop By Brand
            </NavLink>
            <NavLink
              to="/sale-offers"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Sale & Offers
            </NavLink>
            <NavLink
              to="/submit-prescription"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Submit Prescription
            </NavLink>
            <NavLink
              to="/health-services"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Health Services
            </NavLink>
            <NavLink
              to="/store-locator"
              className="block py-2 hover:text-gray-800"
              onClick={toggleMenu}
            >
              Store Locator
            </NavLink>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Cart Drawer */}
      <AnimatePresence>
        {cartOpen && (
          <motion.div
            ref={cartRef}
            className="fixed top-0 right-0 w-full sm:w-96 h-full bg-blue-100 shadow-lg z-50"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="relative h-full flex flex-col p-6">
              <div className="flex justify-between items-center pb-4 border-b">
                <h2 className="text-xl font-semibold text-gray-700">
                  Shopping Cart
                </h2>
                <X
                  className="cursor-pointer text-gray-500"
                  onClick={toggleCart}
                />
              </div>

              <div className="flex-1 overflow-y-auto mt-4">
                {cartItems.length === 0 ? (
                  <p className="text-center text-gray-500 mt-10">
                    Your cart is empty.
                  </p>
                ) : (
                  cartItems.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between mt-4 border p-4 rounded-lg shadow-sm bg-white"
                    >
                      <img
                        src={item.image || "default-image-url"}
                        alt={item.itemName}
                        className="w-16 h-16 rounded-lg mr-4"
                      />
                      <div className="flex-1">
                        <p className="text-sm font-semibold text-gray-700">
                          {item.itemName}
                        </p>
                        <p className="text-sm text-gray-700 mt-2">
                          Quantity: {item.quantity}
                        </p>
                        <div className="flex items-center mt-2">
                          <MinusCircle
                            className="text-gray-500 cursor-pointer"
                            onClick={() =>
                              updateCartItemQuantity(item.id, item.quantity - 1)
                            }
                          />
                          <span className="mx-2 text-gray-700">
                            {item.quantity}
                          </span>
                          <PlusCircle
                            className="text-gray-500 cursor-pointer"
                            onClick={() =>
                              updateCartItemQuantity(item.id, item.quantity + 1)
                            }
                          />
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-gray-700">
                          Ksh{" "}
                          {item.retailPrice
                            ? (
                                parseFloat(item.retailPrice) * item.quantity
                              ).toFixed(2)
                            : "0.00"}
                        </p>
                        <Trash
                          className="text-red-500 cursor-pointer mt-2"
                          onClick={() => removeFromCart(item.id)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>

              <div className="mt-6">
                <button
                  onClick={handleViewCart}
                  className="w-full bg-green-600 text-white py-2 rounded-md shadow hover:bg-green-700"
                >
                  View Cart
                </button>
                <button
                  onClick={handleCheckout}
                  className={`w-full bg-gray-800 text-white py-2 mt-4 rounded-md shadow ${
                    cartItems.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-900"
                  }`}
                  disabled={cartItems.length === 0}
                >
                  Proceed to Checkout
                </button>
                <button
                  onClick={clearCart}
                  className={`w-full mt-2 bg-red-500 text-white py-2 rounded-md shadow ${
                    cartItems.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-red-600"
                  }`}
                  disabled={cartItems.length === 0}
                >
                  Clear Cart
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
