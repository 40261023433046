import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from './Cart'; 
import { PathFinderLoader } from './components/PathFinderLoader'; // Import your loading component

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`https://dawamaxma.dawamtaani.co.ke/api/pharmacy/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch product details');
        }
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return <PathFinderLoader />; // Use the custom loading animation
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  // Split precautions into bullet points based on sentence endings.
  const precautionsList = product.precautions 
    ? product.precautions.split('.').filter(sentence => sentence.trim() !== '') 
    : [];


  return (
    <div className="container mx-auto px-4 py-16">
      <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-8">
        {/* Product Image */}
        <div className="lg:w-1/3">
          <img 
            src={product.image ? `https://dawamaxma.dawamtaani.co.ke${product.image}` : '/default-image-path'} 
            alt={product.itemName} 
            className="w-full h-auto object-cover rounded-lg mb-4"
          />
        </div>
        
        {/* Product Details */}
        <div className="lg:w-2/3">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">{product.itemName}</h1>
          <p className="text-2xl text-green-500 mb-4">Ksh {parseFloat(product.retailPrice).toFixed(2)}</p>
          
          {/* Description */}
          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">Description</h2>
            <p className="text-gray-600">{product.description || 'No description available'}</p>
          </div>

          {/* Precautions */}
          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">Precautions</h2>
            {precautionsList.length > 0 ? (
              <ul className="list-disc list-inside text-gray-600">
                {precautionsList.map((precaution, index) => (
                  <li key={index}>{precaution.trim()}.</li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No precautions available</p>
            )}
          </div>


          {/* Action Buttons */}
          <div className="flex space-x-4 mt-6">
          <button
          onClick={() => addToCart(product)} 
          className="w-full bg-green-500 text-white py-3 rounded-full hover:bg-gray-900 mt-8">
            + Add to Cart
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
