import React from 'react';
import { ChevronUp, Facebook, Twitter, Youtube } from 'react-feather';

const Footer = () => {
  return (
    <footer className="bg-gray-100">
      <div className="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 lg:pt-24">
        <div className="absolute right-4 top-4 sm:right-6 sm:top-6 lg:right-8 lg:top-8">
          <a
            className="inline-block rounded-full bg-teal-600 p-2 text-white shadow transition hover:bg-teal-500 sm:p-3 lg:p-4"
            href="/"
          >
            <span className="sr-only">Back to top</span>
            <ChevronUp />
          </a>
        </div>

        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div className="flex justify-center lg:justify-start mb-8 lg:mb-0">
            <img
              src={process.env.PUBLIC_URL + '/logo512.png'}
              alt="logo"
              className="object-contain h-auto w-32 lg:w-40"
            />
          </div>

          <div className="flex flex-col items-center lg:flex-row lg:items-center lg:gap-16">
            <nav className="mb-8 lg:mb-0">
              <div className="flex space-x-6">
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                  <Twitter className="text-gray-600 hover:text-teal-600 transition" />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                  <Youtube className="text-gray-600 hover:text-teal-600 transition" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                  <Facebook className="text-gray-600 hover:text-teal-600 transition" />
                </a>
              </div>
            </nav>

            <ul className="flex flex-col lg:flex-row lg:gap-12 items-center text-center lg:text-right space-y-4 lg:space-y-0">
              <li>
                <button
                  className="text-gray-700 transition hover:text-gray-700/75"
                  onClick={() => (window.location.href = '/about')}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  className="text-gray-700 transition hover:text-gray-700/75"
                  onClick={() => (window.location.href = '/services')}
                >
                  Services
                </button>
              </li>
              <li>
                <button
                  className="text-gray-700 transition hover:text-gray-700/75"
                  onClick={() => (window.location.href = '/projects')}
                >
                  Projects
                </button>
              </li>
              <li>
                <button
                  className="text-gray-700 transition hover:text-gray-700/75"
                  onClick={() => (window.location.href = '/blog')}
                >
                  Blog
                </button>
              </li>
            </ul>
          </div>
        </div>

        <p className="mt-12 text-center text-sm text-gray-500 lg:text-right">
          Copyright © {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
