import React from 'react';
import { motion } from 'framer-motion';
//import bloodSugarImage from '../assets/blood-sugar.jpg'; // Add an appropriate image

const BloodSugarCheck = () => {
  return (
    <motion.div
      className="min-h-screen p-8 bg-red-50"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold text-center text-red-700 mb-6">
        Blood Sugar Check
      </h1>
      <img
        src="https://images.unsplash.com/photo-1714642596931-2293df25c4a3?q=80&w=1028&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Blood Sugar Check"
        className="w-full max-w-2xl mx-auto mb-8 rounded-lg shadow-md"
      />
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Monitoring your blood sugar levels is crucial, especially if you have diabetes or are at risk of developing it. Our blood sugar check service provides accurate, on-the-spot testing to help you manage your glucose levels effectively.
      </p>
      <h2 className="text-2xl font-semibold text-red-700 mt-8 mb-4">
        Why Blood Sugar Monitoring is Important
      </h2>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>Helps in managing diabetes by keeping track of blood sugar levels.</li>
        <li>Enables timely adjustments to medication, diet, or exercise routines.</li>
        <li>Prevents complications such as nerve damage, kidney problems, and heart disease.</li>
        <li>Empowers you with the information needed to make healthier lifestyle choices.</li>
      </ul>
      <h2 className="text-2xl font-semibold text-red-700 mt-8 mb-4">
        How We Can Help
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Our staff will conduct a quick blood sugar test using a small blood sample from your fingertip. You’ll receive immediate results and personalized recommendations based on your readings. We also offer ongoing support and guidance to help you stay on top of your blood sugar levels.
      </p>
      <h2 className="text-2xl font-semibold text-red-700 mt-8 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: What should my blood sugar levels be?</strong><br />
        A: Normal fasting blood sugar levels should be between 70-100 mg/dL. Post-meal levels should be less than 140 mg/dL. Levels may vary based on individual health conditions, so consult with a healthcare provider.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: How can I manage high blood sugar levels?</strong><br />
        A: Managing blood sugar involves a combination of medication, healthy eating, regular physical activity, and monitoring your levels regularly. Consult with our professionals for personalized advice.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto">
        Visit us for a quick and reliable blood sugar check, and take control of your health today.
      </p>
    </motion.div>
  );
};

export default BloodSugarCheck;
