import React, { useState } from 'react';
import { Mail, MapPin, Clock } from 'react-feather';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import FeaturedProducts from './FeaturedProducts';
import Modal from './components/UploadModal'; 
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Services } from './components/Services';
import NewsletterAndBlogSection from './components/Blog';
import CountUpStats from './components/Stats';
const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="font-sans">
      {/* Header Section */}
      <header className="w-full bg-white relative">
      <div className="flex flex-col md:flex-row justify-between items-center p-4 bg-gray-100 text-gray-600 space-y-2 md:space-y-0">
  <div className="flex items-center space-x-4">
    <Clock className="h-5 w-5" />
    <span className="text-sm">09:00 to 17:00</span>
  </div>
  <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
    <div className="flex items-center space-x-2">
      <Mail className="h-5 w-5" />
      <span className="text-sm">maxmapharmacy@gmail.com</span>
    </div>
    <div className="flex items-center space-x-2">
      <MapPin className="h-5 w-5" />
      <span className="text-sm">P.O BOX test - 00606 Nairobi Kenya</span>
    </div>
  </div>
</div>


        {/* Carousel for ads */}
        <div className="w-full bg-white relative" style={{ height: '20vh', paddingTop: '0' }}>
          <Carousel 
            showArrows={true} 
            showThumbs={false} 
            infiniteLoop 
            autoPlay 
            interval={5000}
            className="h-full"
          >
            <div className="h-full flex items-center justify-center">
              <img 
                src={process.env.PUBLIC_URL + '/logo512.png'} 
                alt="Ad 1" 
                className="object-contain h-full w-auto max-h-20vh md:max-h-64 lg:max-h-80"  
              />
            </div>
            <div className="h-full flex items-center justify-center">
              <img 
                src={process.env.PUBLIC_URL + '/logo512.png'} 
                alt="Ad 2" 
                className="object-contain h-full w-auto max-h-20vh md:max-h-64 lg:max-h-80"
              />
            </div>
            <div className="h-full flex items-center justify-center">
              <img 
                src={process.env.PUBLIC_URL + '/logo512.png'} 
                alt="Ad 3" 
                className="object-contain h-full w-auto max-h-20vh md:max-h-64 lg:max-h-80"
              />
            </div>
          </Carousel>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between items-center h-full px-4 md:px-10 py-16 md:py-20 bg-white">
          <motion.div
            className="w-full md:w-1/2 text-center md:text-left mb-10 md:mb-0"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2.0, ease: "easeOut" }}
          >
            <p className="text-green-700 text-xl font-bold mb-2">100% genuine Products</p>
            <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
              Are you in need of medication or seeking a consultation with a Pharmacist close to your location?
            </h1>
            <p className="text-gray-600 mb-6">
              Maxma is here to support you at any time, offering a seamless experience to meet your healthcare needs and beauty. Whether it’s finding the right medicine or connecting with a healthcare professional nearby, Maxma is your trusted partner, always ready to assist you whenever you need it most. Your health and well-being are our top priorities.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <button 
                className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={() => navigate('/shop')}
              >
                Products
              </button>
              <button 
                className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={toggleModal}
              >
                Upload Prescription
              </button>
            </div>
          </motion.div>
          <div className="w-full md:w-1/2 h-full flex justify-center items-center">
            <img 
              src={process.env.PUBLIC_URL + '/logo192.png'} 
              alt="Hero" 
              className="object-contain max-w-xs max-h-xs md:max-w-md md:max-h-md"
            />
          </div>
        </div>
      </header>

      {/* Featured Products Section */}
      <FeaturedProducts />

      <Services />
      <CountUpStats />

      <NewsletterAndBlogSection />

      {/* Modal Component */}
      {isModalOpen && <Modal closeModal={toggleModal} />}
    </div>
  );
};

export default LandingPage;
