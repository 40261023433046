import React from 'react';
import { motion } from 'framer-motion';
//import counsellingImage from '../assets/counselling.jpg'; // Add an appropriate image

const CounsellingServices = () => {
  return (
    <motion.div
      className="min-h-screen p-8 bg-red-100"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold text-center text-red-800 mb-6">
        Professional Counselling Services
      </h1>
      <img
        src="https://images.unsplash.com/photo-1528716321680-815a8cdb8cbe?q=80&w=1076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Counselling Services"
        className="w-full max-w-2xl mx-auto mb-8 rounded-lg shadow-md"
      />
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Our professional counselling services offer support for a range of issues, including general wellbeing and addiction recovery. Our trained counselors provide a safe, confidential space where you can explore your concerns and work towards positive change.
      </p>
      <h2 className="text-2xl font-semibold text-red-800 mt-8 mb-4">
        Our Services Include:
      </h2>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>Individual counseling sessions to help you manage stress, anxiety, and other personal challenges.</li>
        <li>Support for addiction recovery, including alcohol and substance abuse.</li>
        <li>Relationship counseling to improve communication and resolve conflicts.</li>
        <li>Workshops and group sessions focused on personal development and coping skills.</li>
      </ul>
      <h2 className="text-2xl font-semibold text-red-800 mt-8 mb-4">
        Why Choose Us?
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Our counselors are experienced professionals dedicated to helping you achieve your personal goals. We take a compassionate, non-judgmental approach, and our services are tailored to meet your individual needs.
      </p>
      <h2 className="text-2xl font-semibold text-red-800 mt-8 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: Is counselling confidential?</strong><br />
        A: Yes, all our counselling services are strictly confidential. Your privacy is our top priority, and we ensure a safe and secure environment for all clients.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: How do I book a counselling session?</strong><br />
        A: You can book a session by calling us directly, visiting our website, or stopping by our office. We offer flexible scheduling to accommodate your needs.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto">
        Reach out to us today to schedule a session and take the first step towards a healthier, happier you.
      </p>
    </motion.div>
  );
};

export default CounsellingServices;
