import React, { useRef } from "react";
import { useScroll, motion, useTransform } from "framer-motion";
import { ArrowRight, User, Monitor, Heart, Activity, Clipboard } from "react-feather";

const CARD_HEIGHT = 400;

// Define your service cards here
const CARDS = [
  {
    id: 1,
    Icon: Clipboard,
    title: "Nutritional Supplements",
    description: "Access a wide variety of nutritional supplements to support your health.",
    ctaClasses: "bg-green-300",
    routeTo: "/nutritional-supplements",
  },
  {
    id: 2,
    Icon: Activity,
    title: "Blood Pressure Check",
    description: "Get your blood pressure checked to monitor and maintain your health.",
    ctaClasses: "bg-blue-300",
    routeTo: "/blood-pressure-check",
  },
  {
    id: 3,
    Icon: Monitor,
    title: "Blood Sugar Check",
    description: "Regular blood sugar checks to keep your levels in control.",
    ctaClasses: "bg-red-300",
    routeTo: "/blood-sugar-check",
  },
  {
    id: 4,
    Icon: Heart,
    title: "Skin Care Advice",
    description: "Receive expert advice on maintaining healthy skin.",
    ctaClasses: "bg-yellow-300",
    routeTo: "/skin-care-advice",
  },
  {
    id: 5,
    Icon: User,
    title: "Medicational Counselling Services",
    description: "General counselling and addiction counselling services are offered.",
    ctaClasses: "bg-red-500",
    routeTo: "/counselling-services",
  },
];

export const Services = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  return (
    <section className="bg-white py-16 w-full">
      <div className="w-full px-4 max-w-none mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Our Services</h2>
        <div ref={ref} className="relative w-full">
          {CARDS.map((card, index) => (
            <ServiceCard
              key={card.id}
              card={card}
              scrollYProgress={scrollYProgress}
              position={index + 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ position, card, scrollYProgress }) => {
  const scaleFromPct = (position - 1) / CARDS.length;
  const y = useTransform(scrollYProgress, [scaleFromPct, 1], [0, -CARD_HEIGHT]);

  const isOddCard = position % 2;

  return (
    <motion.div
      style={{
        height: CARD_HEIGHT,
        y: position === CARDS.length ? undefined : y,
        background: isOddCard ? "black" : "white",
        color: isOddCard ? "white" : "black",
      }}
      className="sticky top-0 flex w-full origin-top flex-col items-center justify-center px-4"
    >
      <card.Icon className="mb-4 text-4xl" />
      <h3 className="mb-6 text-center text-2xl md:text-4xl font-semibold">
        {card.title}
      </h3>
      <p className="mb-8 max-w-lg text-center text-sm md:text-base">
        {card.description}
      </p>
      <a
        href={card.routeTo}
        className={`flex items-center gap-2 rounded px-6 py-4 text-base font-medium uppercase transition-all hover:-translate-x-0.5 hover:-translate-y-0.5 md:text-lg ${
          card.ctaClasses
        } ${
          isOddCard
            ? "shadow-[4px_4px_0px_white] hover:shadow-[8px_8px_0px_white]"
            : "shadow-[4px_4px_0px_black] hover:shadow-[8px_8px_0px_black]"
        }`}
      >
        <span>Learn more</span>
        <ArrowRight />
      </a>
    </motion.div>
  );
};
