import React from 'react';
import { motion } from 'framer-motion';
//import bloodPressureImage from '../assets/blood-pressure.jpg'; // Add an appropriate image

const BloodPressureCheck = () => {
  return (
    <motion.div
      className="min-h-screen p-8 bg-blue-50"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold text-center text-blue-700 mb-6">
        Blood Pressure Check
      </h1>
      <img
        src="https://images.unsplash.com/photo-1621525434111-87a99d170b0e?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Blood Pressure Check"
        className="w-full max-w-2xl mx-auto mb-8 rounded-lg shadow-md"
      />
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Regular blood pressure checks are essential for maintaining heart health and preventing serious conditions like hypertension and stroke. Our service provides quick, accurate blood pressure measurements to help you keep your health in check.
      </p>
      <h2 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        Benefits of Regular Blood Pressure Checks
      </h2>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>Early detection of high blood pressure allows for timely intervention.</li>
        <li>Helps to monitor the effectiveness of lifestyle changes or medications.</li>
        <li>Reduces the risk of heart disease, stroke, and other complications.</li>
        <li>Peace of mind knowing your blood pressure is under control.</li>
      </ul>
      <h2 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        What to Expect
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        During your visit, our trained staff will take your blood pressure using advanced, accurate equipment. You’ll receive immediate feedback on your results, along with personalized advice on how to manage your blood pressure through diet, exercise, and other lifestyle changes.
      </p>
      <h2 className="text-2xl font-semibold text-blue-700 mt-8 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: How often should I get my blood pressure checked?</strong><br />
        A: It's recommended to check your blood pressure at least once a year if you’re at normal risk, and more frequently if you have a history of hypertension or other risk factors.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: Can lifestyle changes help lower my blood pressure?</strong><br />
        A: Yes, lifestyle changes such as a balanced diet, regular exercise, stress management, and limiting alcohol can significantly impact your blood pressure levels.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto">
        Visit us today for a blood pressure check and take the first step towards a healthier heart.
      </p>
    </motion.div>
  );
};

export default BloodPressureCheck;
