import React, { useState, useEffect } from 'react';
import { useCart } from './Cart'; 
import Footer from './Footer';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const defaultImage = "https://images.unsplash.com/photo-1471864190281-a93a3070b6de?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3";
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://dawamaxma.dawamtaani.co.ke/api/pharmacy');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const filteredProducts = products.filter(product => 
    product.itemName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-16">
        <p className="text-xl">Loading products...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center py-16">
        <p className="text-xl text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <motion.div
      className="container mx-auto px-4 py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.0 }}
    >
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Shop All Products</h2>
      <div className="flex justify-center mb-6">
        <input 
          type="text" 
          placeholder="Search products..." 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
          className="py-2 px-4 border border-gray-300 rounded-full w-full max-w-md"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {filteredProducts.length > 0 ? filteredProducts.map((product) => (
          <motion.div
            key={product.id}
            className="bg-white shadow-md rounded-lg p-6"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img 
              src={product.image ? `https://dawamaxma.dawamtaani.co.ke${product.image}` : defaultImage} 
              alt={product.itemName} 
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{product.itemName}</h3>
            <p className="text-gray-600 mb-4">Ksh {product.retailPrice}</p>
            <div className="flex justify-between">
                <button 
                  onClick={() => addToCart(product)} 
                  className="w-1/2 bg-gray-800 text-white py-2 rounded-full hover:bg-gray-900 mr-2"
                >
                  Add to Cart
                </button>
                <button 
                  onClick={() => navigate(`/product-details/${product.id}`)} 
                  className="w-1/2 bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600"
                >
                  View Product
                </button>
              </div>
          </motion.div>
        )) : (
          <p className="text-center text-gray-600">No products found.</p>
        )}
      </div>
      <Footer />
    </motion.div>
  );
};

export default Shop;
