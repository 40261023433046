import React, { useEffect, useState, useRef } from "react";
import { motion, useAnimate } from "framer-motion";

export const PathFinderLoader = () => {
  return (
    <div className="grid h-72 place-content-center bg-neutral-950 p-4">
      <ShuffleLoader />
    </div>
  );
};

const NUM_BLOCKS = 5; // Number of blocks in the loader
const BLOCK_SIZE = 32; // Size of each block
const DURATION_IN_MS = 175; // Duration of each animation step in milliseconds
const DURATION_IN_SECS = DURATION_IN_MS * 0.001; // Convert milliseconds to seconds for Framer Motion

const TRANSITION = {
  ease: "easeInOut",
  duration: DURATION_IN_SECS,
};

const ShuffleLoader = () => {
  const [blocks, setBlocks] = useState(
    Array.from(Array(NUM_BLOCKS).keys()).map((n) => ({ id: n }))
  );
  const [scope, animate] = useAnimate();

  const isMounted = useRef(false); // Track if component is mounted

  useEffect(() => {
    isMounted.current = true; // Mark component as mounted
    shuffle(); // Start shuffling blocks when the component mounts
    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, []);

  const shuffle = async () => {
    while (isMounted.current) {
      // Safeguard to ensure scope is not null
      if (!scope.current) {
        await delay(100); // Wait briefly if scope is not ready
        continue;
      }

      // Pick two random blocks to swap
      const [first, second] = pickTwoRandom();

      // Check if both elements exist before trying to animate
      const firstEl = scope.current.querySelector(`[data-block-id="${first.id}"]`);
      const secondEl = scope.current.querySelector(`[data-block-id="${second.id}"]`);

      if (!firstEl || !secondEl) {
        console.error("Elements not found. Skipping animation.");
        await delay(100); // Wait briefly before the next iteration
        continue; // Skip this iteration if elements are not found
      }

      // Animate the first block moving up
      await animate(firstEl, { y: -BLOCK_SIZE }, TRANSITION);

      // Animate the second block moving down
      await animate(secondEl, { y: BLOCK_SIZE }, TRANSITION);

      // Wait for the duration of the animation
      await delay(DURATION_IN_MS);

      // Swap the positions of the two blocks in state
      setBlocks((prevBlocks) => {
        const copy = [...prevBlocks];
        const indexForFirst = copy.indexOf(first);
        const indexForSecond = copy.indexOf(second);
        copy[indexForFirst] = second;
        copy[indexForSecond] = first;
        return copy;
      });

      // Wait a bit longer before resetting positions
      await delay(DURATION_IN_MS * 2);

      // Reset the blocks back to their original position
      await animate(firstEl, { y: 0 }, TRANSITION);
      await animate(secondEl, { y: 0 }, TRANSITION);

      // Wait before the next shuffle iteration
      await delay(DURATION_IN_MS);
    }
  };

  // Helper function to pick two random blocks
  const pickTwoRandom = () => {
    const index1 = Math.floor(Math.random() * blocks.length);
    let index2 = Math.floor(Math.random() * blocks.length);
    while (index2 === index1) {
      index2 = Math.floor(Math.random() * blocks.length);
    }
    return [blocks[index1], blocks[index2]];
  };

  // Helper function to create a delay
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return (
    <div ref={scope} className="flex divide-x divide-neutral-950">
      {blocks.map((b) => {
        return (
          <motion.div
            layout
            data-block-id={b.id}
            key={b.id}
            transition={TRANSITION}
            style={{
              width: BLOCK_SIZE,
              height: BLOCK_SIZE,
            }}
            className="bg-white"
          />
        );
      })}
    </div>
  );
};

export default PathFinderLoader;
