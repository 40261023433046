import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ParallaxProvider } from "react-scroll-parallax";
import { CartProvider } from './PharmaStore/Cart';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ParallaxProvider>
    <CartProvider>
    <App />
  </CartProvider>,
  </ParallaxProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

