import React from 'react';
import { motion } from 'framer-motion';
//import supplementImage from '../assets/supplements.jpg'; // Make sure to place an appropriate image in your assets folder

const NutritionalSupplements = () => {
  return (
    <motion.div
      className="min-h-screen p-8 bg-green-50 "
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold text-center text-green-700 mb-6">
        Nutritional Supplements
      </h1>
      <img
        src="https://images.unsplash.com/photo-1693996045346-d0a9b9470909?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Nutritional Supplements"
        className="w-full max-w-2xl mx-auto mb-8 rounded-lg shadow-md"
      />
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Discover a variety of nutritional supplements designed to support your health and wellbeing. We offer a wide range of vitamins, minerals, herbal remedies, and other dietary supplements tailored to your individual needs, helping you maintain optimal health naturally.
      </p>
      <h2 className="text-2xl font-semibold text-green-700 mt-8 mb-4">
        Why Choose Our Supplements?
      </h2>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>High-quality products sourced from reputable manufacturers.</li>
        <li>Formulas designed to address specific health needs, including immune support, energy boosts, bone health, and more.</li>
        <li>Products tested for purity, potency, and safety to ensure the best results.</li>
        <li>Expert advice available to help you choose the right supplements for your lifestyle and health goals.</li>
      </ul>
      <h2 className="text-2xl font-semibold text-green-700 mt-8 mb-4">
        How to Get Started
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Visit our store or contact us to speak with a health professional who can guide you through selecting the best supplements for your needs. We offer personalized consultations to help you make informed choices and support your journey to better health.
      </p>
      <h2 className="text-2xl font-semibold text-green-700 mt-8 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: Are your supplements safe to use?</strong><br />
        A: Yes, all our supplements are tested for safety, purity, and potency. We only source products from reputable manufacturers who meet the highest industry standards.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: How do I know which supplements are right for me?</strong><br />
        A: Our team of health professionals can help you determine which supplements are best suited to your individual needs based on your health goals, dietary restrictions, and other factors.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto">
        Start today and take the first step towards a healthier, more vibrant life with our comprehensive range of nutritional supplements.
      </p>
    </motion.div>
  );
};

export default NutritionalSupplements;
