import React from 'react';
import { useCart } from '../Cart';
import { Trash2, PlusCircle, MinusCircle } from 'react-feather';

const ViewCart = () => {
  const { cartItems, removeFromCart, clearCart, updateCartItemQuantity } = useCart();

  const totalCost = cartItems.reduce((total, item) => {
    const price = item.retailPrice ? parseFloat(item.retailPrice) : 0; // Fallback to 0 if undefined
    return total + (price * item.quantity);
  }, 0);

  const handleCheckout = () => {
    if (cartItems.length === 0) return;
    const message = cartItems.map(item => `${item.quantity} x ${item.itemName} at ${item.retailPrice}`).join(', ');
    const whatsappUrl = `https://wa.me/254101757541?text=I am interested in: ${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="container mx-auto px-4 py-16 font-sans">
      <h1 className="text-3xl font-bold mb-8">Shopping Cart</h1>

      {cartItems.length === 0 ? (
        <p className="text-center text-gray-500">Your cart is empty.</p>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-6">
            {cartItems.map((item, index) => (
              <div key={index} className="flex items-center justify-between p-4 border rounded-lg shadow-sm bg-white">
                <img src={item.image || 'default-image-url'} alt={item.itemName} className="w-24 h-24 rounded-lg mr-4" />
                <div className="flex-1">
                  <p className="text-lg font-semibold text-gray-700">{item.itemName}</p>
                  <p className="text-sm text-gray-500">{item.description}</p>
                  <div className="flex items-center mt-2">
                    <MinusCircle 
                      className="text-gray-500 cursor-pointer"
                      onClick={() => updateCartItemQuantity(item.id, item.quantity - 1)}
                    />
                    <span className="mx-2 text-gray-700">{item.quantity}</span>
                    <PlusCircle 
                      className="text-gray-500 cursor-pointer"
                      onClick={() => updateCartItemQuantity(item.id, item.quantity + 1)}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold text-gray-700">
                    Ksh {(parseFloat(item.retailPrice) * item.quantity).toFixed(2)}
                  </p>
                  <Trash2 
                    className="text-red-500 cursor-pointer mt-2"
                    onClick={() => removeFromCart(item.id)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="p-8 border rounded-lg shadow-lg bg-white">
            <h2 className="text-2xl font-bold mb-6">Cart Total</h2>
            <div className="mb-4">
              <p className="text-lg font-semibold">Subtotal: <span className="float-right">Ksh {totalCost.toFixed(2)}</span></p>
              <p className="text-lg font-semibold">Shipping Cost: <span className="float-right">FREE</span></p>
            </div>
            <div className="border-t pt-4">
              <p className="text-lg font-bold">Total Cost: <span className="float-right">Ksh {totalCost.toFixed(2)}</span></p>
            </div>
            <button 
              onClick={handleCheckout}
              className="w-full bg-orange-500 text-white py-2 mt-6 rounded-md shadow hover:bg-orange-600"
            >
              Checkout
            </button>
            <button 
              onClick={clearCart} 
              className="w-full bg-red-500 text-white py-2 mt-4 rounded-md shadow hover:bg-red-600"
            >
              Clear Cart
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCart;
