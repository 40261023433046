// src/components/BlogSection.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TextParallaxContent from './textParallaxContent'; // Ensure the correct casing and path

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const imageBaseUrl = 'https://dawamaxma.dawamtaani.co.ke';

  useEffect(() => {
    fetch('https://dawamaxma.dawamtaani.co.ke/api/blogs')
      .then((response) => response.json())
      .then((data) => setBlogs(data))
      .catch((error) => console.error('Error fetching blogs:', error));
  }, []);

  const truncateContent = (content, maxLength = 150) => {
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };

  // Get the latest three blogs
  const latestBlogs = blogs.slice(0, 3);

  return (
    <div className="bg-white py-10">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Latest Blogs</h2>
      <div className="container mx-auto px-4">
        {latestBlogs.map((blog) => {
          const fullImageUrl = blog.image ? `${imageBaseUrl}/${blog.image}` : `${imageBaseUrl}/default-image.jpg`;

          return (
            <TextParallaxContent
              key={blog.id}
              imgUrl={fullImageUrl}
              heading={blog.title}
              subheading={blog.subheading}
            >
              <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-8 pt-4 md:grid-cols-12">
                <div className="col-span-1 md:col-span-8 md:col-start-3">
                  <p className="text-xl leading-relaxed text-neutral-700 md:text-2xl md:leading-loose mb-4">
                    {truncateContent(blog.content)}
                  </p>
                  <Link
                    to={`/blogs/${blog.id}`}
                    className="text-pink-600 hover:text-pink-800 transition-colors duration-200 text-lg font-semibold block text-right"
                  >
                    Read More <span className="inline-block transform transition-transform duration-200 hover:translate-x-1">→</span>
                  </Link>
                </div>
              </div>
            </TextParallaxContent>
          );
        })}
        {/* Link to view all blogs */}
        <div className="text-center mt-8">
          <Link
            to="/blogs"
            className="text-pink-600 hover:text-pink-800 transition-colors duration-200 text-lg font-semibold"
          >
            View All Blogs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
