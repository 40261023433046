import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./PharmaStore/Navbar";
import Hero from "./PharmaStore/Hero";
//import Cart from "./PharmaStore/Cart";
import {
  AdminPanel,
  AllBlogs,
  BlogDetail,
  BloodPressureCheck,
  BloodSugarCheck,
  Counselling,
  NutritionalSupplements,
  ProductDetail,
  Shop,
  SkinCareAdvice,
  ViewCart,
} from "./utils";
import Footer from "./PharmaStore/Footer";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Footer />
              </>
            }
          />
          <Route path="/shop" element={<Shop />} />
          <Route path="/view-cart" element={<ViewCart />} />
          <Route path="/mwenye-kiti" element={<AdminPanel />} />
          <Route path="/product-details/:id" element={<ProductDetail />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route
            path="/nutritional-supplements"
            element={<NutritionalSupplements />}
          />
          <Route
            path="/blood-pressure-check"
            element={<BloodPressureCheck />}
          />
          <Route path="/blood-sugar-check" element={<BloodSugarCheck />} />
          <Route path="/skin-care-advice" element={<SkinCareAdvice />} />
          <Route path="/counselling-services" element={<Counselling />} />
          <Route path="/blogs" element={<AllBlogs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
