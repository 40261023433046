import React from 'react';
import { motion } from 'framer-motion';
//import skinCareImage from '../assets/skin-care.jpg'; // Add an appropriate image

const SkinCareAdvice = () => {
  return (
    <motion.div
      className="min-h-screen p-8 bg-yellow-50"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold text-center text-yellow-700 mb-6">
        Skin Care Advice
      </h1>
      <img
        src="https://images.unsplash.com/photo-1600428853876-fb5a850b444f?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt="Skin Care Advice"
        className="w-full max-w-2xl mx-auto mb-8 rounded-lg shadow-md"
      />
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Your skin is your body’s largest organ, and taking care of it is essential for overall health and confidence. Our expert skin care advice service provides personalized recommendations tailored to your skin type and concerns.
      </p>
      <h2 className="text-2xl font-semibold text-yellow-700 mt-8 mb-4">
        What We Offer
      </h2>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>Personalized skin assessments to identify your specific skin type and needs.</li>
        <li>Customized skincare routines and product recommendations.</li>
        <li>Advice on dealing with common skin issues such as acne, dryness, and aging.</li>
        <li>Guidance on sun protection, diet, and lifestyle factors that affect skin health.</li>
      </ul>
      <h2 className="text-2xl font-semibold text-yellow-700 mt-8 mb-4">
        Our Approach
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-4">
        Our skincare experts will work with you to create a routine that fits your lifestyle and addresses your concerns. Whether you need help selecting the right products or managing a specific condition, we’re here to support your journey to healthier, more radiant skin.
      </p>
      <h2 className="text-2xl font-semibold text-yellow-700 mt-8 mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: How often should I update my skincare routine?</strong><br />
        A: It’s recommended to update your routine as your skin changes, which can be seasonal or based on your skin’s response to products. Consulting with a skincare professional can help you make timely adjustments.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-2">
        <strong>Q: What should I avoid in skincare products?</strong><br />
        A: Avoid products with harsh chemicals, parabens, sulfates, and artificial fragrances if you have sensitive skin. Always patch-test new products and seek professional advice for personalized recommendations.
      </p>
      <p className="text-lg text-gray-700 max-w-2xl mx-auto">
        Book a consultation with our skin care experts today and start your journey to better skin health.
      </p>
    </motion.div>
  );
};

export default SkinCareAdvice;
